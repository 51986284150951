const typeList = {
    TEXT: 1,
    NUMBER: 2,
    SELECTONE: 3,
    SELECTMANY: 4,
    TEXTAREA: 5,
    ILOOKUP: 6
};

export default typeList;
